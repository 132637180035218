import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Container, Layout } from '../../components/Layout'
import throttle from 'lodash.throttle'

import {
  Heading2,
  Heading4,
  BodyText1,
  Heading5,
} from '../../components/Heading'
import { Slider, SliderMethods } from '../../components/Slider'
import { useIsMd } from '../../utils/media'

import './index.scss'

interface ServiceCardItem {
  title: string
  contents: { title: string; sub: string }[]
}

interface ServiceCardProps {
  title: string
  sub: string
  contents: ServiceCardItem[]
  className?: string
}

export const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  sub,
  contents = [],
  className = '',
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const sliderRef = useRef<SliderMethods>(null)
  const isMd = useIsMd()
  const [width, setWidth] = useState<string | number>('auto')

  const calc = throttle(() => {
    if (ref.current) {
      setWidth(isMd ? 'auto' : ref.current.getBoundingClientRect().width)
    }
  }, 100)

  useEffect(() => {
    setTimeout(() => {
      sliderRef.current?.reCalc()
    }, 0)
  }, [width])

  useEffect(() => {
    calc()

    window.addEventListener('resize', calc, true)
    return () => {
      window.removeEventListener('resize', calc, true)
    }
  }, [calc])

  return (
    <div className={`service-card ${className}`}>
      <div className="main">
        <Heading4>{title}</Heading4>
        <Heading5 as="p">{sub}</Heading5>
      </div>
      <div className="detail" ref={ref}>
        <Slider container={ref} hideNums={true} drag={!isMd} ref={sliderRef}>
          {contents.map((content, index) => {
            return (
              <div className="item" key={index.toString()} style={{ width }}>
                <div>
                  <Heading4>{content.title}</Heading4>
                  {content.contents.map((co, index) => {
                    return (
                      <div key={index.toString()}>
                        {!!co.title && <Heading5 as="p">{co.title}</Heading5>}
                        <BodyText1>{co.sub}</BodyText1>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export interface Contents {
  title: string
  sub: string
  contents: Contents[]
}

export const ServicesPage: React.FC<
  PageData<{
    services: APIResponse<{
      title: string
      head: SectionCard
      slogan: string
      contents: APIListResponse<Contents>
    }>
  }>
> = ({ pageContext }) => {
  const {
    serverData: { services },
  } = pageContext
  const { head, contents } = services.data.attributes

  const sections: ServiceCardProps[] = contents.data.map((item) => {
    return {
      ...item.attributes,
    }
  })

  return (
    <Layout className="services-page">
      <Helmet>
        <title>{services.data.attributes.title}</title>
      </Helmet>
      <Container className="head">
        <Heading2>{head.title}</Heading2>
        <BodyText1>{head.description}</BodyText1>
      </Container>
      {/* <Container className="eco">
        <Section
          title="初心企业服务生态协同"
          icon={<ServiceEyeIcon />}
          content="新生代创业者是初心不断迭代投后能力的“试验田”。通过重度服务新生代创业者，初心建立了深度、模块化的投后能力，为初心系被投企业提供一系列支持。"></Section>
      </Container> */}
      <Container className="slogan">
        <Heading4>{services.data.attributes.slogan}</Heading4>
      </Container>
      <Container className="contents">
        {sections.map((section, index) => {
          return (
            <ServiceCard
              key={index.toString()}
              {...section}
              className={`bg-0${(index + 1).toString()}`}
            />
          )
        })}
      </Container>
    </Layout>
  )
}
